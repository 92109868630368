<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab">
			<a-card v-if="activeTab === 'domain'">
				<a-tabs size="large" default-active-key="domain">
					<!-- Domain -->
					<a-tab-pane key="domain" tab="Domain">
						<a-form-model ref="ruleForm" :model="domain" :rules="DNSRules">
							<a-row :gutter="16" class="mt-3" v-if="parentDomain.name">
								<a-col :span="12">
									<a-form-model-item ref="parentName" label="Parent Project Domain Name"
										prop="parentName">
										<a-input v-model="parentDomain.name"
											placeholder="Enter Parent Project Domain name" size="large"
											:disabled="!!parentDomain.name">
											<a-icon v-if="parentDomain.name" slot="addonAfter" type="copy"
												@click="copy(parentDomain.name)" />
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="12">
									<div v-if="parentDomain.verified && parentDomain.hasSSLCertificate"
										class="py-2 px-3 text-center verified">
										Verified
									</div>
									<div v-else class="py-2 px-3 text-center unverified">
										Unverified
									</div>
								</a-col>
							</a-row>
							<a-row :gutter="10" class="mt-3">
								<a-col :span="24" :md="12">
									<a-form-model-item ref="name" prop="name">
										<template slot="label">
											Domain Name
											<a-tooltip overlayClassName="change-tooltip-color">
												<template slot="title">
													Recommended to use a
													subdomain instead of the
													root domain. Here’s an
													example: mail.example.ca
												</template>
												<a-icon type="question-circle" style="
                                                        font-size: 14px;
                                                        color: black;
                                                        margin-left: 5px;
                                                    " />
											</a-tooltip>
										</template>
										<a-input v-model="domain.name" placeholder="Enter Domain name" size="large"
											:disabled="domainCreated">
											<a-icon v-if="domainCreated" slot="addonAfter" type="copy"
												@click="copy(domain.name)" />
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="12" :md="6">
									<div v-if="domain.verified && domain.hasSSLCertificate"
										class="py-2 px-3 text-center verified">
										Verified
									</div>
								</a-col>
								<a-col :span="12" :md="6" style="
                                        margin-top: 25px;
                                        justify-content: end;
                                        display: flex;
                                    ">
									<div class="dF aC" style="gap: 30px">
										<a-button v-if="!domainCreated" size="large" type="primary" class="style-blue"
											@click="addDomain" :loading="loading">Add Domain</a-button>
										<a-button v-if="domainCreated &&
											!domain.verified
										" size="large" type="primary" @click="verifyDomain" :loading="loading">Verify</a-button>
										<a-button v-if="domainCreated &&
											domain.verified &&
											!domain.hasSSLCertificate
										" size="large" type="primary" @click="generateSSL" :loading="loading">Generate SSL</a-button>
										<a-button v-if="domainCreated" size="large" type="danger"
											@click="deleteDomain">Delete</a-button>
									</div>
								</a-col>
							</a-row>
						</a-form-model>
					</a-tab-pane>

					<!-- DNS Records -->
					<a-tab-pane key="dns">
						<template slot="tab">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Can take up to 48 hours for DNS records to
									propagate
								</template>
								DNS Records
								<a-icon type="question-circle" style="font-size: 14px;" />
							</a-tooltip>
						</template>
						<template>
							<a-card class="mt-3">
								<template slot="title">
									<h4>Sending records</h4>
									<div>
										TXT records (known as SPF & DKIM) are <strong> required to send and receive
										</strong> email with
										Bildhive.
									</div>
								</template>

								<div>
									<h5>SPF</h5>
									<a-row :gutter="16" class="mt-3" v-if="DNS.SPF.name && DNS.SPF.value">
										<a-col :span="24" :md="10">
											<a-form-model-item label="Hostname">
												<a-input v-model="DNS.SPF.name" size="large" ref="spfName" disabled>
													<a-icon slot="addonAfter" type="copy" @click="copy(DNS.SPF.name)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="10">
											<a-form-model-item label="Value">
												<a-input v-model="DNS.SPF.value" size="large" ref="spfValue" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.SPF.value)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="4">
											<div v-if="DNS.SPF.valid" class="py-2 px-3 text-center verified">
												Verified
											</div>
											<div v-else class="py-2 px-3 text-center unverified">
												Unverified
											</div>
										</a-col>
									</a-row>
								</div>
								<div class="mt-3">
									<h5>DKIM</h5>
									<a-row :gutter="16" class="mt-3" v-if="DNS.DKIM.name && DNS.DKIM.value">
										<a-col :span="24" :md="10">
											<a-form-model-item label="Hostname">
												<a-input v-model="DNS.DKIM.name" size="large" ref="dkimName" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.DKIM.name)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="10">
											<a-form-model-item label="Value">
												<a-input v-model="DNS.DKIM.value" size="large" ref="dkimValue" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.DKIM.value)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="4">
											<div v-if="DNS.DKIM.valid" class="py-2 px-3 text-center verified">
												Verified
											</div>
											<div v-else class="py-2 px-3 text-center unverified">
												Unverified
											</div>
										</a-col>
									</a-row>
								</div>
							</a-card>
						</template>

						<template>
							<a-card class="mt-5">
								<template slot="title">
									<h4>Receiving records</h4>
									<div style="white-space: normal">
										MX records are <strong>recommended for all domains, even if you are only sending
											messages</strong>. Unless you already have MX records for <strong> @{{
												domain.name
											}} </strong> pointing
										to another email provider (e.g. Gmail), you should update the following records.
									</div>
								</template>

								<template>
									<h5>MX</h5>
									<a-row :gutter="16" class="mt-3" v-if="DNS.MX[0]?.name && DNS.MX[0]?.value">
										<a-col :span="24" :md="10">
											<a-form-model-item label="Hostname">
												<a-input v-model="DNS.MX[0].name" size="large" ref="spfName" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.MX[0].name)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="10">
											<a-form-model-item label="Value">
												<a-input v-model="DNS.MX[0].value" size="large" ref="spfValue" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.MX[0].value)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="4">
											<div v-if="DNS.MX[0].valid" class="py-2 px-3 text-center verified">
												Verified
											</div>
											<div v-else class="py-2 px-3 text-center unverified">
												Unverified
											</div>
										</a-col>
									</a-row>
									<a-row :gutter="16" class="mt-3" v-if="DNS.MX[1]?.name && DNS.MX[1]?.value">
										<a-col :span="24" :md="10">
											<a-form-model-item label="Hostname">
												<a-input v-model="DNS.MX[1].name" size="large" ref="spfName" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.MX[1].name)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="10">
											<a-form-model-item label="Value">
												<a-input v-model="DNS.MX[1].value" size="large" ref="spfValue" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.MX[1].value)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="4">
											<div v-if="DNS.MX[1].valid" class="py-2 px-3 text-center verified">
												Verified
											</div>
											<div v-else class="py-2 px-3 text-center unverified">
												Unverified
											</div>
										</a-col>
									</a-row>
								</template>
							</a-card>
						</template>

						<template>
							<a-card class="mt-5">
								<template slot="title">
									<h4>Tracking records</h4>
									<div>
										The CNAME record is necessary for <strong>tracking opens, clicks, and
											unsubscribes.
										</strong>
									</div>
								</template>

								<template>
									<h5>CNAME</h5>
									<a-row :gutter="16" class="mt-3" v-if="DNS.CNAME.name && DNS.CNAME.value">
										<a-col :span="24" :md="10">
											<a-form-model-item label="Hostname">
												<a-input v-model="DNS.CNAME.name" size="large" ref="cnameName" disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.CNAME.name)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="10">
											<a-form-model-item label="Value">
												<a-input v-model="DNS.CNAME.value" size="large" ref="cnameValue"
													disabled>
													<a-icon slot="addonAfter" type="copy"
														@click="copy(DNS.CNAME.value)" />
												</a-input>
											</a-form-model-item>
										</a-col>
										<a-col :span="24" :md="4">
											<div v-if="DNS.CNAME.valid" class="py-2 px-3 text-center verified">
												Verified
											</div>
											<div v-else class="py-2 px-3 text-center unverified">
												Unverified
											</div>
										</a-col>
									</a-row>
								</template>
							</a-card>
						</template>
					</a-tab-pane>
				</a-tabs>
			</a-card>
			<a-card v-else-if="activeTab === 'email'" title="Email Verification">
				<a-form-model ref="ruleForm">
					<div v-for="(sender, senderI) in newSenders" :key="sender + senderI">
						<a-row :gutter="16" v-if="sender.sender.active && !sender.clicked">
							<a-col :span="24" :md="8">
								<a-form-model-item prop="name">
									<label class="mb-0 text-success" slot="label">
										Name
										<span v-if="sender.sender.active">
											<a-icon type="check-circle" />
										</span>
									</label>
									<a-input v-model="sender.sender.name" size="large" disabled />
								</a-form-model-item>
							</a-col>

							<a-col :span="24" :md="8">
								<a-form-model-item prop="email">
									<label class="mb-0 text-success" slot="label">
										Email
										<span v-if="sender.sender.active" class="inline-block">
											<a-icon class="text-success" type="check-circle" />
										</span>
									</label>
									<a-input v-model="sender.sender.email" size="large" disabled />
								</a-form-model-item>
							</a-col>

							<a-col :span="24" :md="6">
								<a-button @click="changeSender(senderI)" type="primary" size="large"
									style="margin: 25px;">CHANGE</a-button>
							</a-col>
						</a-row>

						<a-row :gutter="16" v-else-if="!sender.sender.active && !sender.clicked">
							<a-col :span="24" :md="8">
								<a-form-model-item label="Name" prop="name">
									<a-input v-model="sender.sender.name" size="large" disabled />
								</a-form-model-item>
							</a-col>

							<a-col :span="24" :md="8">
								<a-form-model-item label="Email" prop="email">
									<a-input v-model="sender.sender.email" size="large" disabled />
								</a-form-model-item>
							</a-col>

							<a-col :span="24" :md="6">
								<div class="dF aC jSB">
									<a-button @click="sender.clicked = true" class="mr-2" type="primary" size="large"
										style="margin: 25px;">CHANGE</a-button>
									<a-button @click="verifySender(senderI)" type="primary" size="large"
										style="margin: 25px;">RESEND</a-button>
								</div>
							</a-col>
						</a-row>

						<a-row :gutter="16" v-else>
							<a-col :span="24" :md="8">
								<a-form-model-item label="Name" prop="name">
									<a-input v-model="sender.sender.name" size="large" />
								</a-form-model-item>
							</a-col>

							<a-col :span="24" :md="8">
								<a-form-model-item label="Email" prop="email">
									<a-input v-model="sender.sender.email" size="large" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="6">
								<a-button @click="verifySender(senderI)" type="primary" size="large"
									style="margin: 25px;">VERIFY</a-button>
							</a-col>
						</a-row>
					</div>
				</a-form-model>
				<a-alert type="info" show-icon style="width: fit-content;"
					v-if="domain && domain.name && domain.verified">
					<template slot="message">
						You can use any username for your verified domain for example <strong>
							marketing@{{ domain.name }}, sales@{{ domain.name }}</strong>
					</template>
				</a-alert>
				<div @click="addNewEmail" style="
                        margin-top: 24px;
                        color: var(--orange);
                        cursor: pointer;
                        display: inline-block;
                    ">
					<div class="dF aC">
						<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
						<div>Add New Email</div>
					</div>
				</div>
			</a-card>
			<div v-else-if="activeTab === 'unsubscribe'">
				<unsubscribe />
			</div>
		</SettingsTab>
	</div>
</template>

<script>
import { validateEmail } from "bh-mod";
import BHLoading from 'bh-mod/components/common/Loading'
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import unsubscribe from '@/components/common/unsubscribe'

export default {
	components: {
		SettingsTab,
		BHLoading,
		unsubscribe,
	},
	data() {
		return {
			activeTab: 'domain',
			verifying: false,
			clicked: false,
			sender: {},
			currentSender: {},
			newSenders: [
				{
					sender: {},
					currentSender: {},
					clicked: false,
				},
			],
			loading: false,
			domainCreated: false,
			DNS: {
				SPF: { name: "", value: "", valid: false },
				DKIM: { name: "", value: "", valid: false },
				MX: [{ name: "", value: "", valid: false }, { name: "", value: "", valid: false }],
				CNAME: { name: "", value: "", valid: false },
			},
			domain: { name: "", verified: false, hasSSLCertificate: false },
			parentDomain: { name: "", verified: false, hasSSLCertificate: false },
			rules: {
				email: [
					{
						required: true,
						message: "Please input a sender email",
						trigger: "blur",
					},
				],
				name: [
					{
						required: true,
						message: "Please input a sender name",
						trigger: "blur",
					},
				],
			},
			DNSRules: {
				name: [
					{
						required: true,
						message: "Please input a domain name",
						trigger: "blur",
					},
				],
			},
			menuList: [
				{
					label: 'Domain & Email Settings', id: 'sub1', children: [
						{ label: 'Sending Domain', id: 'domain' },
						{ label: 'Sender Email Address', id: 'email' },
						{ label: 'Unsubscribe', id: 'unsubscribe' },
					]
				},
			],
			unsubscribePageDesign: {
				logo: '',
				primaryColor: '#0d6efd',
				primaryFont: 'Georgia',
			}
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},

		fonts() {
			return this.$store.getters.fonts
		},

		fontList() {
			return this.fonts.map(x => ({
				label: x.name,
				value: x.name
			}))
		},
	},
	methods: {
		addNewEmail() {
			this.newSenders.push({
				sender: {},
				currentSender: {},
				clicked: true,
			});
		},
		verify(sender) {
			if (!sender.sender.name.trim())
				return this.$message.error(
					"Sender name is required. Please try again."
				);
			if (!validateEmail(sender.sender.email))
				return this.$message.error(
					"Invalid Email input. Please try again."
				);
			if (sender.sender && sender.sender.id) {
				this.$api
					.put(`/senders/${this.instance.id}/${sender.sender.id}`, {
						name: sender.sender.name,
						email: sender.sender.email,
					})
					.then(({ data }) => {
						this.verifying = true;
						this.$message.success(
							data.active
								? "Great your email has been verified."
								: "Great! You've been sent an email for verification."
						);
						sender.clicked = false;
						this.$store.state.email.senders[data.id] = data;
						this.addSenders();
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
			} else {
				this.$api
					.post(`/senders/${this.instance.id}`, {
						name: sender.sender.name,
						email: sender.sender.email,
					})
					.then(({ data }) => {
						this.verifying = true;
						this.$message.success(
							data.active
								? "Great your email has been verified."
								: "Great! You've been sent an email for verification."
						);
						sender.clicked = false;
						this.$store.state.email.senders[data.id] = data;
						this.addSenders();
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
			}
		},
		changeSender(index) {
			let sender = this.newSenders[index];
			sender.currentSender = JSON.parse(JSON.stringify(sender.sender));
			sender.clicked = true;
		},
		validateEmail,
		verifySender(index) {
			let sender = this.newSenders[index];
			if (sender.currentSender.email == sender.sender.email) {
				sender.clicked = false;
			} else {
				sender.sender.active = false;
				this.verify(sender);
			}
		},
		onTabChange(item) {
			this.activeTab = item;
		},

		resetDomainData() {
			this.domainCreated = false;
			this.DNS = {
				SPF: { name: "", value: "" },
				DKIM: { name: "", value: "" },
				CNAME: { name: "", value: "" },
			};
			this.domain = { name: "", verified: false, hasSSLCertificate: false };
		},

		setDomainDetails(data) {
			if (data.length) {
				const childInstanceData = data.find((d) => !d.inherited);
				if (childInstanceData) {
					this.domainCreated = true;
					this.domain.name = childInstanceData.name;
					this.domain.verified = childInstanceData.isVerified;
					this.domain.hasSSLCertificate = childInstanceData.hasSSLCertificate;
					if (
						childInstanceData.providerData && childInstanceData.providerData.sending_dns_records
							.length > 2
					) {
						this.DNS.SPF.name =
							childInstanceData.providerData.sending_dns_records[0].name;
						this.DNS.SPF.value =
							childInstanceData.providerData.sending_dns_records[0].value;
						this.DNS.SPF.valid =
							childInstanceData.providerData
								.sending_dns_records[0].valid === "valid";

						this.DNS.DKIM.name =
							childInstanceData.providerData.sending_dns_records[1].name;
						this.DNS.DKIM.value =
							childInstanceData.providerData.sending_dns_records[1].value;
						this.DNS.DKIM.valid =
							childInstanceData.providerData
								.sending_dns_records[1].valid === "valid";

						this.DNS.CNAME.name =
							childInstanceData.providerData.sending_dns_records[2].name;
						this.DNS.CNAME.value =
							childInstanceData.providerData.sending_dns_records[2].value;
						this.DNS.CNAME.valid =
							childInstanceData.providerData
								.sending_dns_records[2].valid === "valid";
					}

					if (
						childInstanceData.providerData && childInstanceData.providerData.receiving_dns_records
							.length > 1
					) {
						this.DNS.MX[0].name = childInstanceData.providerData?.domain?.name;
						this.DNS.MX[0].value =
							childInstanceData.providerData.receiving_dns_records[0].value;
						this.DNS.MX[0].valid =
							childInstanceData.providerData
								.receiving_dns_records[0].valid === "valid";

						this.DNS.MX[1].name = childInstanceData.providerData?.domain?.name;
						this.DNS.MX[1].value =
							childInstanceData.providerData.receiving_dns_records[1].value;
						this.DNS.MX[1].valid =
							childInstanceData.providerData
								.receiving_dns_records[1].valid === "valid";
					}
				}

				const parentInstanceData = data.find((d) => d.inherited);
				if (parentInstanceData) {
					this.parentDomain.name = parentInstanceData.name;
					this.parentDomain.verified = parentInstanceData.isVerified;
					this.parentDomain.hasSSLCertificate = parentInstanceData.hasSSLCertificate;
				}
			}
		},

		async addDomain() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					this.loading = true;
					try {
						const { data } = await this.$api.post(
							`/sending-domains/${this.instance.id}`,
							{
								name: this.domain.name,
							}
						);
						this.$message.success("Domain added successfully.");
						this.$store.commit("SET_DOMAIN", {
							name: data.name,
							isVerified: data.isVerified,
							hasSSLCertificate: data.hasSSLCertificate,
						});
						this.fetchSendingDomain();
					} catch (error) {
						console.error("Error while adding new domain", error);
						this.$message.error(
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							"Error while adding new domain. Please try again!"
						);
					}
					this.loading = false;
				}
			});
		},

		async fetchSendingDomain() {
			try {
				const { data } = await this.$api.get(
					`/sending-domains/${this.instance.id}`
				);
				this.setDomainDetails(data);
			} catch (error) {
				console.error("Error while fetching domain details", error);
				// this.$message.error(
				//     (error.response &&
				//         error.response.data &&
				//         error.response.data.message) ||
				//         "Error while fetching domain details. Please try again!"
				// );
			}
		},

		async verifyDomain() {
			this.loading = true;
			try {
				const { data } = await this.$api.put(
					`/sending-domains/${this.instance.id}/verify`
				);
				if (data.isVerified) {
					this.$message.success("Domain verified successfully.");
					this.$store.commit("SET_DOMAIN", {
						name: data.name,
						isVerified: data.isVerified,
						hasSSLCertificate: data.hasSSLCertificate,
					});
					this.fetchSendingDomain(data);
				}
			} catch (error) {
				console.error("Error while verifying domain", error);
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while verifying domain. Please try again!"
				);
			}
			this.loading = false;
		},

		async generateSSL() {
			this.loading = true;
			try {
				const { data } = await this.$api.put(
					`/sending-domains/${this.instance.id}/request-ssl-certificate`
				);
				if (data.isVerified) {
					this.$message.success("SSL certificate generated successfully.");
					this.$store.commit("SET_DOMAIN", {
						name: data.name,
						isVerified: data.isVerified,
						hasSSLCertificate: data.hasSSLCertificate,
					});
					this.fetchSendingDomain(data);
				}
			} catch (error) {
				console.error("Error while generating SSL certificate", error);
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while generating SSL certificate. Please try again!"
				);
			}
			this.loading = false;
		},

		async deleteDomain() {
			this.loading = true;
			try {
				await this.$api.delete(`/sending-domains/${this.instance.id}`);
				this.$message.success("Domain deleted successfully.");
				this.$store.commit("SET_DOMAIN", {
					name: "",
					isVerified: false,
					hasSSLCertificate: false
				});
				this.resetDomainData();
			} catch (error) {
				console.error("Error while deleting domain", error);
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while deleting domain. Please try again!"
				);
			}
			this.loading = false;
		},

		copy(value) {
			navigator.clipboard.writeText(value);
			this.$message.success("Copied");
		},

		addSenders() {
			let senders = this.$store.state.email.senders;
			this.newSenders = [];
			for (var i in senders) {
				let senderObj = {
					sender: JSON.parse(JSON.stringify(senders[i])),
					currentSender: {},
					clicked: false,
				};
				this.newSenders.push(senderObj);
			}
		}
	},
	created() {
		this.fetchSendingDomain();
		this.$store.commit("CLOSE_DRAWERS");
		this.$store.commit("EMAIL_TYPE", { value: "Settings" });
		this.addSenders();
	},
};
</script>


<style scoped>
.verified {
	color: #fff;
	border-radius: 4px;
	width: fit-content;
	margin-top: 30px;
	background-color: #28c791;
}

.unverified {
	color: #fff;
	border-radius: 4px;
	width: fit-content;
	margin-top: 30px;
	background-color: #f86469;
}
</style>
