<template>
	<div>
		<a-row :gutter="16">
			<a-col :sm="24" :md="12" :lg="12">
				<a-card>
					<a-tabs default-active-key="Unsubscribe Reasons" size="large">
						<a-tab-pane key="Unsubscribe Reasons" tab="Unsubscribe Reasons">
							<a-table :columns="columns" :data-source="records" :rowKey="(r) => r.id + r.reason">
								<div slot="action" slot-scope="obj">
									<a-icon type="edit" class="cursor-pointer hover:text-primary"
										@click="editReason(obj)" />
									<i class="fa fa-trash ml-4 cursor-pointer hover:text-primary"
										@click="deleteReason(obj)" />
								</div>
							</a-table>
							<div class="dF" style="gap: 50px; color:var(--orange);">
								<div @click="addReason" class="dF aC cursor-pointer">
									<a-icon style="font-size:25px" type="plus-circle" class="mr-2" />
									<span>Add Unsubscribe Reason</span>
								</div>

								<div @click="exportModal.visible = true" class="dF aC cursor-pointer">
									<a-icon style="font-size:25px" type="upload" class="mr-2" />
									<span>Export Unsubscribe Reasons</span>
								</div>
							</div>
						</a-tab-pane>
						<a-tab-pane key="Design" tab="Design" force-render>
							<a-form-model ref="unsubscribePageDesign" :model="unsubscribePageDesign" class="mx-3">
								<h5 class="mb-4">Logo</h5>
								<a-row>
									<a-col :span="8">
										<a-form-model-item>
											<ImageBoxSelector :removable="true" type="images"
												:key="unsubscribePageDesign.logo"
												v-model="unsubscribePageDesign.logo" />
										</a-form-model-item>
									</a-col>
								</a-row>
								<a-divider />
								<h5 class="mb-4">Font and Color Settings</h5>
								<a-row>
									<a-col :span="14">
										<a-form-model-item label="Primary Font"
											:rules="req('Please select primary font')" prop="primaryFont">
											<a-select v-model="unsubscribePageDesign.primaryFont" :options="fontList"
												style="width: 50%;" />
										</a-form-model-item>
									</a-col>
									<a-col :span="10" v-if="unsubscribePageDesign.primaryColor">
										<a-form-model-item :rules="req('Please enter purchaser first name')"
											prop="primaryColor">
											<template slot="label">
												Primary Color
												<a-tooltip overlayClassName="change-tooltip-color"
													title="The color will only be applied to the selection buttons that the user chooses, and the background color of the unsubscribe button.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
											<ColorPicker v-model="unsubscribePageDesign.primaryColor" placement="top"
												@input="updateStyles" />
										</a-form-model-item>
									</a-col>
								</a-row>
							</a-form-model>
							<a-divider></a-divider>
							<a-button class="pull-right" size="large" type="primary" @click="saveUnSubPageSettings"
								:loading="loading">SAVE</a-button>
						</a-tab-pane>
					</a-tabs>
				</a-card>
			</a-col>
			<a-col :sm="24" :md="12" :lg="12">
				<a-card>
					<h5 class="mb-5">Unsubscribe Page Preview</h5>
					<div class="w-full preview" :style="`font-family: ${unsubscribePageDesign.primaryFont} !important`">
						<template v-if="unsubscribePageDesign.logo">
							<div class="d-flex justify-content-center align-items-center mb-5">
								<img :src="unsubscribePageDesign.logo" alt="logo" style="max-width: 150px;" />
							</div>
						</template>

						<div class="mb-4">
							<h1>Unsubscribe Preferences</h1>
						</div>
						<template v-if="instancesForUnsubscribe.length > 1">
							<div class="mb-3">
								<label for="reason" class="form-label">Select your unsubscribe preference:</label>
								<div v-for="instance in instancesForUnsubscribe" :key="instance.id"
									:class="`form-check ${instance.isParent ? 'mb-1' : 'ms-2'}`">
									<input class="form-check-input disabled" type="checkbox" :id="instance.id"
										:checked="instance.checked" @change="onCheckStatusChange(instance, $event)">
									<label class="form-check-label" :for="instance.id">
										{{ instance.marketingName || instance.name }}
										{{ instance.isParent ? '- All Communication' : '' }}
									</label>
								</div>
							</div>
						</template>
						<template v-if="unsubscribeReasons.length">
							<div class="mb-3">
								<label for="reason" class="form-label">Select a reason from below</label>
								<div v-for="unsubscribe in unsubscribeReasons" :key="unsubscribe.id" class="form-check">
									<input class="form-check-input reason-radio" type="radio" name="reason"
										:value="unsubscribe.reason" @input="otherReason = ''">
									<label class="form-check-label">
										{{ unsubscribe.reason }}
									</label>
								</div>
							</div>

							<div class="mb-3">
								<label for="reason_text" class="form-label">Other (Please specify):</label>
								<textarea v-model="otherReason" class="reason-textarea form-control" name="reason_text"
									id="reason_text" rows="3" @input="clearRadioChecks"></textarea>
							</div>
						</template>
						<button class="btn btn-primary fw-bold" type="submit"
							@click.prevent="() => { }">Unsubscribe</button>
					</div>

					<template v-if="instancesForUnsubscribe.length > 1">
						<a-divider></a-divider>
						<a-alert type="info" show-icon>
							<template slot="message">
								<span>
									The Unsubscribe Page Preview shows only the projects where you are listed as a lead
									in the CRM, based on
									your email({{ user.email }}). The options displayed will vary for each user
									depending on the projects they are associated with.
								</span>
								<br>
								<br>
								<span>
									The project list will display the marketing name if it’s set up for the project;
									otherwise, it will show the project name.
								</span>
							</template>
						</a-alert>
					</template>
				</a-card>
			</a-col>
		</a-row>

		<a-modal :title="`${modal.edit ? 'Edit' : 'Add New'} Unsubscribe Reason`"
			:okText="`${modal.edit ? 'UPDATE' : 'ADD'}`" :visible="modal.visible" @cancel="close" @ok="submit" centered>
			<a-row :gutter="16">
				<a-form-model ref="modal" :model="modal">
					<a-col :span="24">
						<a-form-model-item prop="reason" label="Unsubscribe Reason" required
							:rules="req('Please enter the reason')">
							<a-input v-model="modal.reason" placeholder="Enter the Reason" size="large"></a-input>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>

		<a-modal title="Export to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportReasons" centered>
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select v-model="exportModal.instance" placeholder="Select a Project" size="large"
								show-search :filter-option="filterOption">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
									}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
	import ColorPicker from '@/components/email/ColorPicker'
	import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

	export default {
		components: {
			ColorPicker,
			ImageBoxSelector
		},
		data() {
			return {
				loading: false,
				refreshKey: 0,
				exportModal: {
					visible: false,
					instance: null,
				},
				modal: {
					edit: false,
					visible: false,
					reason: '',
					id: 0
				},
				columns: [
					{
						title: 'Reason',
						key: 'reason',
						dataIndex: 'reason',
					},
					{
						title: 'Action',
						key: 'action',
						scopedSlots: { customRender: 'action' },
					},
				],
				unsubscribePageDesign: {
					logo: '',
					primaryColor: '#0d6efd',
					primaryFont: 'Georgia',
				},
				designUpdated: false,
				instancesForUnsubscribe: [],
				unsubscribeReasons: [],
				otherReason: ''
			}
		},
		computed: {
			appSettings() {
				console.log('this.refreshKey', this.refreshKey)
				let app = this.$store.state.email.allSettings.app;
				if (!app) app = { options: {} };
				return app.options;
			},
			reasonList() {
				return this.appSettings.unsubscribeReasons || [];
			},
			records() {
				return this.reasonList.map((r, i) => {
					return {
						reason: r,
						id: i
					}
				})
			},
			instance() {
				return this.$store.state.instance
			},
			user() {
				return this.$store.state.user.user
			},
			instances() {
				return this.$store.state.instances.filter(x => x.id != this.instance.id && x.name).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
			},
			instancesForUnsubscribeList() {
				return this.$store.state.instancesForUnsubscribe;
			},

			fonts() {
				return this.$store.getters.fonts
			},

			fontList() {
				return this.fonts.map(x => ({
					label: x.name,
					value: x.name
				}))
			},

			unsubscribePageDesignSettings() {
				return {
					logo: this.appSettings.unsubscribePageDesign.logo || '',
					primaryFont: this.appSettings.unsubscribePageDesign.primaryFont || 'Georgia',
					primaryColor: this.appSettings.unsubscribePageDesign.primaryColor || '#0d6efd',
				}
			},

			primaryShadowColor() {
				let hex = this.unsubscribePageDesign.primaryColor
				let r = parseInt(hex.slice(1, 3), 16),
					g = parseInt(hex.slice(3, 5), 16),
					b = parseInt(hex.slice(5, 7), 16);

				return `rgba(${r}, ${g}, ${b}, 0.2)`;
			},
		},
		watch: {
			appSettings: {
				deep: true,
				handler(val) {
					this.$api.post(`/settings/:instance/email`, {
						options: val,
					}).then(() => {
						if (this.designUpdated) {
							this.designUpdated = false;
							this.$message.success('Your design has been updated successfully!')
						} else {
							this.unsubscribeReasons = val.unsubscribeReasons.map((r, i) => ({ reason: r, id: i, checked: false }))
						}
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
				},
			},
		},
		methods: {
			req: msg => ({ required: true, message: msg }),

			close() {
				this.$refs.modal && this.$refs.modal.resetFields()
				this.modal.visible = false
				this.modal.edit = false
			},

			editReason(item) {
				item = JSON.parse(JSON.stringify(item))
				this.modal = {
					...this.modal,
					edit: true,
					visible: true,
					...item
				}
			},

			addReason() {
				this.modal = {
					edit: false,
					visible: true,
					reason: '',
					id: Number(this.records.length)
				}
				this.modal.visible = true
			},

			submit() {
				let self = this;
				this.$refs.modal && this.$refs.modal.validate(valid => {
					if (valid) {
						let { visible, edit, ...item } = self.modal
						item = JSON.parse(JSON.stringify(item))

						this.designUpdated = false;
						if (self.modal.edit) {
							self.$store.commit('UPDATE_UNSUBSCRIBE_REASONS', { action: 'update', index: item.id, reason: item.reason.trim() })
							self.refreshKey++;
							self.$message.success('Unsubscribe reason updated successfully!')
						} else {
							const found = self.reasonList.find(r => r.toLowerCase().trim() === item.reason.toLowerCase().trim());
							if (found) {
								return self.$message.error('Same reason already exists. Please add new reason.')
							}
							self.$store.commit('UPDATE_UNSUBSCRIBE_REASONS', { action: 'add', reason: item.reason.trim() })
							self.$message.success('Unsubscribe reason added successfully!')
						}
						self.close()
					} else {
						return false;
					}
				})
			},

			deleteReason(item) {
				this.$confirm({
					title: "Delete Unsubscribe Reason",
					content: h => <div>Do you want to delete unsubscribe reason <strong>{item.reason}?</strong></div>,
					okText: 'DELETE',
					okType: 'danger',
					cancelText: 'CANCEL',
					centered: true,
					onOk: () => {
						this.$store.commit('UPDATE_UNSUBSCRIBE_REASONS', { action: 'remove', index: item.id })
						this.refreshKey++;
						this.$message.success('Unsubscribe reason deleted successfully!')
					}
				})
			},

			filterOption(input, option) {
				return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},

			exportReasons() {
				let self = this;
				this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
					if (valid) {
						self.$store.commit('LOAD', true)
						try {
							await self.$api.post(`settings/:instance/email/clone-settings`, {
								targetInstance: self.exportModal.instance,
								keysToClone: [
									"unsubscribeReasons",
								]
							})
							self.$store.commit('LOAD', false)
							self.$message.success('Successfully exported all the unsubscribed reasons into selected project.')
							self.exportModal = {
								visible: false,
								instance: null
							}
						} catch (err) {
							self.$store.commit('LOAD', false)
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, `An error occurred while exporting unsubscribe reasons to another project. Please try again.`))
							}
						}
					} else {
						return false;
					}
				})
			},

			fetchInstances() {
				this.$api.get('/instances').then(({ data }) => {
					data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
						return {
							id: x.instance.id,
							name: x.instance.name
						}
					})
					this.$store.commit('SET_INSTANCES', data)
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},

			saveUnSubPageSettings() {
				this.$refs.unsubscribePageDesign.validate((valid) => {
					if (valid) {
						let unsubscribePageDesign = JSON.parse(JSON.stringify(this.unsubscribePageDesign));

						this.designUpdated = true;
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "unsubscribePageDesign",
							what: unsubscribePageDesign,
						});
						this.updateStyles();
					}
				})
			},

			updateStyles(color) {
				if (color === '#FFFFFF') return;
				document.documentElement.style.setProperty('--bs-primary', this.unsubscribePageDesign.primaryColor);
				document.documentElement.style.setProperty('--bs-primary-shadow', this.primaryShadowColor);
			},

			async getInstancesForUnsubscribe() {
				try {
					const { data } = await this.$api.get(`instances/:instance/instances-for-unsubscribe`)
					this.$store.commit('SET_INSTANCES_FOR_UNSUBSCRIBE', data)
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching instance child data. Please try again.`))
					}
				}
			},

			onCheckStatusChange(instance, event) {
				if (instance.isParent) {
					this.instancesForUnsubscribe = this.instancesForUnsubscribe.map((i) => ({ ...i, checked: event.target.checked }))
				} else if (!event.target.checked) {
					this.instancesForUnsubscribe = this.instancesForUnsubscribe.map((i) => ({ ...i, checked: i.isParent ? false : true }))
				}
			},

			clearRadioChecks() {
				this.unsubscribeReasons = [...this.unsubscribeReasons.map((r, i) => ({ ...r, id: r.id + i, checked: false }))]
			}
		},

		async created() {
			this.unsubscribePageDesign = { ...this.unsubscribePageDesignSettings }
			this.unsubscribeReasons = this.records.map(r => ({ ...r, checked: false }))
			this.updateStyles();

			if (!this.instances || !this.instances.length) {
				this.fetchInstances();
			}

			if (!this.instancesForUnsubscribeList || !this.instancesForUnsubscribeList.length) {
				await this.getInstancesForUnsubscribe();
			}
			this.instancesForUnsubscribe = this.instancesForUnsubscribeList.map((d) => ({ ...d, checked: d.isSourceInstance ? true : false }));
		}
	}
</script>

<style scoped>
	.fw-bold {
		font-weight: 700 !important
	}

	.preview {
		font-size: var(--bs-body-font-size);
		font-weight: var(--bs-body-font-weight);
		line-height: var(--bs-body-line-height);
		color: var(--bs-body-color);
		text-align: var(--bs-body-text-align);
		background-color: var(--bs-body-bg);
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: transparent;
	}

	h1 {
		margin-top: 0;
		margin-bottom: .5rem;
		font-weight: 500;
		line-height: 1.2;
		color: var(--bs-heading-color);
	}

	/* Override primary button color */
	.btn-primary,
	.btn-primary:hover,
	.btn-primary:visited,
	.btn-primary:active,
	.btn-primary:focus,
	.btn-primary:hover:active {
		background-color: var(--bs-primary) !important;
		border-color: var(--bs-primary) !important;
	}

	/* Override form control border color and box-shadow color */
	.form-control:focus {
		border-color: var(--bs-primary) !important;
		box-shadow: 0 0 0 0.25rem var(--bs-primary-shadow) !important;
	}

	/* Override radio button checked color */
	.form-check-input:checked {
		background-color: var(--bs-primary) !important;
		border-color: var(--bs-primary) !important;
	}

	/* Override radio button focus box-shadow color */
	.form-check-input:focus {
		box-shadow: 0 0 0 0.25rem var(--bs-primary-shadow) !important;
	}
</style>
